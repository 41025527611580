<template>
  <div>
    <div class="site-tab" v-if="isSuper">
      <div class="site-tab-l">
        <p :class="tabType == -1 ? 'active' : ''" @click="changeTab(-1)">全部组织</p>
        <p :class="tabType == index ? 'active' : ''" v-for="(item, index) in typesObj" :key="index"
          @click="changeTab(index)">{{ item }}</p>
        <!--          <p>企业机构</p>-->
        <!--          <p>渠道</p>-->
      </div>
      <div class="site-tab-r">
        <!--      <Button type="primary" style="" @click="add">{{$t('site_add')}}</Button>-->
        <div class="user-list-top" style="margin-bottom: 10px;">
          <div class="search">
            <Button type="primary" @click="exportRecord" style="margin-right: 20px" v-if="!isManager">积分导出</Button>
            <div>
              <Button type="primary" @click="add" style="margin-right: 20px" v-if="!isManager">{{ $t('user_add')
              }}</Button>
            </div>
            <div>
              <Button type="primary" style="margin-right: 20px" @click="changeSite" v-if="isSuper">批量更换组织</Button>
            </div>
            <div>
              <!-- <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
                type: 0
              }" accept=".xlsx,.xls" :headers="{
  'access-token': Cookies.get('liteAccessToken')
}" :on-success="fileSuccess" :before-upload="beforeUpload" :show-upload-list="false">
                <Button type="primary" v-if="isSuper" :loading="fileLoading" style="margin-right:20px;">导入老师数据</Button>
              </Upload> -->
              <el-button type="primary" size="small" style="margin-right: 20px;" @click="importDrawerShow=true">导入老师数据</el-button>
            </div>
            <div>
              <Button type="primary" @click="goMysite" v-if="isUser && !isManager"
                style="margin-right: 20px">生成邀请链接</Button>
            </div>
          </div>
          <div>

          </div>
          <!-- <div class="search_input">
        <Input v-model="keyword" @on-enter="searchList" @on-search="searchList" :placeholder="$t('user_search_tip')"
          clearable search :enter-button="true" />
      </div> -->
        </div>
      </div>
    </div>

    <div class="margin-bottom-10" style="margin-top: 20px;">
      <div class="search">
        <div class="search-item">
          <div class="name">角色:</div>
          <div class="cont">
            <Select v-model="searchRole" placeholder="请选择角色" @on-change="changeRole" clearable size="large"
              style="width: 200px">
              <Option :value="item.value" v-for="item in roleList" :key="item.value">{{ item.label }}</Option>
            </Select>
          </div>
        </div>
        <div class="search-item" v-if="isSuper">
          <div class="name">注册时间:</div>
          <div class="cont">
            <DatePicker :value="date" size="large" format="yyyy/MM/dd" type="daterange" placement="bottom-end"
              placeholder="请选择时间" style="width: 250px" @on-change="changeDate"></DatePicker>
          </div>
        </div>
        <div class="search-item" v-if="isSuper">
          <div class="name">地域:</div>
          <div class="cont">
            <el-cascader :options="cityList" v-model="cityId" :props="optionProps" clearable
              @change="searchList"></el-cascader>
          </div>
        </div>
        <div class="search-item" v-if="isSuper">
          <div class="name">星级评分:</div>
          <div class="cont">
            <Select v-model="starType" placeholder="请选择星级" @on-change="searchList" clearable size="large"
              style="width: 200px">
              <Option :value="item.value" v-for="item in starTypeList" :key="item.value">{{ item.label }}</Option>
            </Select>
          </div>
        </div>
        <div class="search-item">
          <div class="name">实名认证：</div>
          <div class="cont">
            <Select v-model="is_verify_realname" ref="select" clearable
              placeholder="请选择实名认证状态" style="width: 200px" @on-change="searchList">
              <Option v-for="item in realNameList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
        </div>
        <div class="search-item" v-if="isSuper">
          <div class="name">组织筛选：</div>
          <div class="cont">
            <Select v-model="searchSiteId" ref="select" remote clearable :remote-method="rankRemoteSite" filterable
              placeholder="请选择组织" style="width: 200px" @on-change="searchList">
              <Option v-for="item in searchSiteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
        </div>
        <div class="search-item">
          <div class="name">搜索:</div>
          <div class="cont">
            <Input v-model="keyword" @on-enter="searchList" @on-search="searchList" :placeholder="$t('user_search_tip')"
              clearable search />
          </div>
        </div>
      </div>

    </div>
    <div class="table">
      <Table border :columns="superColumns" :data="data" :loading="loading"></Table>
      <!-- <div class="user-item" v-for="item in data" :key="item.id">
      <div class="user-item-top">
        <div><span>ID：{{ item.id }} </span><span v-if="!isManager">|</span><span v-if="!isManager">所属组织：{{ item.site && item.site.name }}</span><span v-if="item.siteUser && item.siteUser.department">|</span><span v-if="item.siteUser && item.siteUser.department">所属专业/岗位：{{ item.siteUser && item.siteUser.department }}</span></div>
        <div><span v-if="!isManager"><img src="../../../assets/images/new_icon/xiaojifen.png" width="20" height="20" /> {{ item.score }}</span><span>注册时间：{{ util.timeFormatter(new Date(+item.created_at*1000), 'yyyy-MM-dd') }}</span><span>|</span><span>{{ statusObj[item.status] }}</span></div>
      </div>
      <div class="user-item-info">
        <div class="user-item-info-l" :class="isManager ? 'flexW' : ''">
          <img :src="item.avatar" />
          <div>
            <p class="user-item-info-l-name"  @click="goInfo(item)">{{ item.nickname }}<span v-if="item.realname">({{ item.realname }})</span></p>
            <div>
              <span>{{ item.mobile }}</span>
              <img src="../../../assets/images/new_icon/Unlock.png" v-if="isSuper && !item.myStatus && item.mobile" @click="showPhone(item)" />
            </div>
          </div>
        </div>
        <div class="user-item-info-role" :class="isManager ? 'flexW' : ''">
          <span v-for="(sItem,index) in item.roles" :key="index" :style="{backgroundColor:roleColor[index]}">{{ sItem.role.description }}</span>
        </div>
        <div class="user-item-info-score" v-if="!isManager">
          <p class="user-item-info-subtit">星级评分</p>
          <p>{{ item.star }}</p>
        </div>
        <div class="user-item-info-time" :class="isManager ? 'flexW' : ''">
          <p class="user-item-info-subtit">更新时间</p>
          <p>{{ util.timeFormatter(new Date(+item.updated_at*1000), 'yyyy-MM-dd hh:mm') }}</p>
        </div>
        <div class="user-item-info-desc" v-if="!isManager">
          <p class="user-item-info-subtit">最新跟进</p>
          <p>{{ item.last_follow_content }}</p>
        </div>
        <div class="user-item-info-btn" :class="isManager ? 'flexW' : ''">
          <Button type="primary" @click="quickLogin(item)" v-if="isSuper && item.access_token_link && !isManager">快捷登录</Button>
          <Button type="primary" @click="showRemark(item)" v-if="isSuper && !isManager">跟进</Button>
          <Button type="info" @click="edit(item)">编辑</Button>
          <Button type="error" @click="remove(item)">删除</Button>
        </div>
      </div>
    </div> -->
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total
        show-sizer show-elevator></Page>
    </div>
    <!-- <div v-if="userModal"> -->
    <CreateUser :isManager="isManager" :userModal="userModal" :userId="curData.id" :roleList="roleList" :statusList="statusList" @close="closeModal"></CreateUser>
    <!-- </div> -->
    <!--  <Modal-->
    <!--      v-model="userModal"-->
    <!--      :title="curData.id ? '编辑用户' : '创建用户'"-->
    <!--      @on-cancel="cancel"-->
    <!--  >-->
    <!--    <Form ref="formValidate" :model="formItem" :rules="formRule" :label-width="80">-->
    <!--      <FormItem label="所属租户" prop="site_id" v-if="!isManager">-->
    <!--        <Select filterable v-model="formItem.site_id" clearable>-->
    <!--          <Option :value="item.id" v-for="item in siteList" :key="item.id + item.name">ID:{{ item.id }}  {{ item.name }}</Option>-->
    <!--        </Select>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="姓名" prop="name">-->
    <!--        <Input v-model="formItem.name" placeholder="Enter something..."></Input>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="昵称" prop="nickName">-->
    <!--        <Input v-model="formItem.nickName" placeholder="Enter something..."></Input>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="手机号码" prop="mobile">-->
    <!--        <Input v-model="formItem.mobile" placeholder="Enter something..."></Input>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="登录名" prop="username">-->
    <!--        <Input v-model="formItem.username" placeholder="Enter something..."></Input>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="密码" prop="password">-->
    <!--        <Input v-model="formItem.password" placeholder="Enter something..."></Input>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="角色" prop="role">-->
    <!--        <Select multiple v-model="formItem.role">-->
    <!--          <Option :value="item.value" v-for="item in roleList" :key="item.value">{{ item.label }}</Option>-->
    <!--        </Select>-->
    <!--      </FormItem>-->
    <!--      <FormItem label="状态" prop="status">-->
    <!--        <Select v-model="formItem.status">-->
    <!--          <Option :value="item.value" v-for="item in statusList" :key="item.value">{{ item.label }}</Option>-->
    <!--        </Select>-->
    <!--      </FormItem>-->
    <!--    </Form>-->
    <!--    <div slot="footer">-->
    <!--      <Button @click="cancel">取消</Button>-->
    <!--      <Button type="primary" @click="confirm" :loading="editLoading">确定</Button>-->
    <!--    </div>-->
    <!--  </Modal>-->
    <Modal v-model="deleModal" title="删除成员" @on-cancel="deleCancel">
      <p>确认删除当前数据吗？</p>
      <div slot="footer">
        <Button @click="deleCancel">取消</Button>
        <Button type="error" @click="dele" :loading="deleLoading">确定</Button>
      </div>
    </Modal>
    <!-- <Modal
      v-model="changeSiteModal"
      title="批量更换组织"
      @on-cancel="cancelChangeSiteModal"
  >
    <Form ref="formChangeSiteValidate" :model="changeSiteFormItem" :rules="changeSiteFormRule" :label-width="80">
      <FormItem label="目标组织" prop="siteId">
        <Select filterable v-model="changeSiteFormItem.siteId" clearable @on-change="getGroupList">
          <Option :value="item.id" v-for="item in siteList" :key="item.id + item.name">ID:{{ item.id }}  {{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="目标班级" prop="groupId">
        <Select filterable v-model="changeSiteFormItem.groupId" clearable>
          <Option :value="item.id" v-for="item in groupList" :key="item.id + item.name">ID:{{ item.id }}  {{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="用户id" prop="userIds">
        <Input v-model="changeSiteFormItem.userIds" type="textarea" placeholder="请输入用户id,每行一个" style="width: 100%" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button @click="cancelChangeSiteModal">取消</Button>
      <Button type="primary" @click="confirmChangeSite">确定</Button>
    </div>
  </Modal> -->
    <!-- <Modal v-model="remarkModal" title="跟进" footer-hide @on-cancel="cancelRemark" width="800px">
      <div>
        <Input v-model="content" :rows="3" type="textarea" placeholder="请输入备注内容..." />
        <div class="top-btn">
          <Button type="primary" class="mt20" @click="confirmRemark">确定</Button>
        </div>

      </div>
      <div class="history">
        <p class="history-tit">历史备注：</p>
        <div class="history-list">
          <div style="position: relative;width: 100%;height: 100px" v-if="remarkLoading">
            <Spin fix></Spin>
          </div>
          <Timeline>
            <TimelineItem v-for="item in remarkList" :key="item.id">
              <p class="time">{{ item.created_at }} <span>|</span>添加人：{{ item.user.nickname }}（{{ item.user.id }}）</p>
              <p class="content">{{ item.content }}</p>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
    </Modal> -->
    <el-drawer :size="800" :visible.sync="changeSiteModal" @close="cancelChangeSiteModal()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">批量更换组织</div>
        <div class="drawer-title-btns">
          <Button @click="cancelChangeSiteModal" style="margin-right: 10px;">取消</Button>
          <Button type="primary" @click="confirmChangeSite">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form ref="formChangeSiteValidate" :model="changeSiteFormItem" :rules="changeSiteFormRule" :label-width="80">
          <FormItem label="目标组织" prop="siteId">
            <Select filterable v-model="changeSiteFormItem.siteId" remote clearable :remote-method="(query)=>rankRemoteSite(query,'batch')" filterable @on-change="getGroupList">
              <Option :value="item.id" v-for="item in batchChangeSite" :key="item.id + item.name">ID:{{ item.id }} {{ item.name
              }}</Option>
            </Select>
          </FormItem>
          <FormItem label="目标班级" prop="groupId">
            <Select filterable v-model="changeSiteFormItem.groupId" clearable>
              <Option :value="item.id" v-for="item in groupList" :key="item.id + item.name">ID:{{ item.id }} {{ item.name
              }}</Option>
            </Select>
          </FormItem>
          <FormItem label="用户id" prop="userIds">
            <Input v-model="changeSiteFormItem.userIds" type="textarea" placeholder="请输入用户id,每行一个" style="width: 100%" />
          </FormItem>
        </Form>
      </div>
    </el-drawer>
    <!-- 跟进 -->
    <el-drawer :size="800" :visible.sync="remarkModal" @close="cancelRemark()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">跟进（{{ curData.nickname }}）</div>
      </div>
      <div style="padding: 20px;">
        <div style="display: flex; align-items: flex-end;">
          <Input v-model="content" :rows="3" type="textarea" placeholder="请输入备注内容..." />
          <Button type="primary" style="margin-left: 20px;" @click="confirmRemark">确定</Button>
        </div>
        <div class="history">
          <p class="history-tit">历史跟进记录：</p>
          <div class="history-list">
            <div style="position: relative;width: 100%;height: 100px" v-if="remarkLoading">
              <Spin fix></Spin>
            </div>
            <Timeline>
              <TimelineItem v-for="item in remarkList" :key="item.id">
                <p class="time">{{ item.created_at }} <span>|</span>跟进人：{{ item.user.nickname }}（{{ item.user.id }}）<span>|</span>创建人：{{ item.creator.nickname }}（{{item.creator.id}}）</p>
                <div class="content-wrap">
                  <p class="content">{{ item.content }}</p>
                  <div>
                    <el-button type="warning" size="mini" @click="updateRemark(item)">编辑</el-button>
                    <el-button type="danger" size="mini" @click="deleteRemark(item)">删除</el-button>
                  </div>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 更新跟进 -->
    <el-drawer :visible.sync='updateRemarkShow' :size="600" @close="updateRemarkDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>更新跟进记录</div>
        <div class='drawer-title-btns'>
          <el-button type="primary" size="small" @click="updateRemarkOK()">确定</el-button>
        </div>
      </div>
      <div style='padding:20px;'>
        <Input v-model="updateForm.content" :rows="3" type="textarea" placeholder="请输入备注内容..." />
      </div>
    </el-drawer>
    <!--    积分导出-->
    <el-drawer :size="800" :visible.sync="exportModal" @close="cancelExport">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">积分导出</div>
        <div class="drawer-title-btns">
          <Button type="primary" @click="confirmExportRecord">确定</Button>
        </div>
      </div>
<!--      <div>-->
<!--        <div class="search-item">-->
<!--          <div class="name">角色:</div>-->
<!--          <div class="cont">-->
<!--            <Select v-model="searchRole" placeholder="请选择角色" @on-change="changeRole" clearable size="large"-->
<!--                    style="width: 200px">-->
<!--              <Option :value="item.value" v-for="item in roleList" :key="item.value">{{ item.label }}</Option>-->
<!--            </Select>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="search-item" v-if="isSuper">-->
<!--          <div class="name">注册时间:</div>-->
<!--          <div class="cont">-->
<!--            <DatePicker :value="date" size="large" format="yyyy/MM/dd" type="daterange" placement="bottom-end"-->
<!--                        placeholder="请选择时间" style="width: 250px" @on-change="changeDate"></DatePicker>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm mt20">
        <el-form-item label="角色" prop="role">
          <el-select v-model="ruleForm.role" placeholder="请选择角色">
            <el-option :label="item.label" :value="item.value" v-for="item in roleList" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="date">
          <el-date-picker
              v-model="ruleForm.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-drawer :visible.sync="importDrawerShow" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">导入老师数据</div>
        <div class="drawer-title-btns">
        </div>
      </div>
      <div style="padding:20px;">

        <el-form>
          <el-form-item label="下载模板">
            <el-button size="small" style="margin-bottom: 20px;" type="primary" @click="downloadTemplate()">下载模板</el-button>
          </el-form-item>
          <el-form-item label="导入数据">
            <Upload :action="constant.URL + '/uploadfile/upload'" :data="{type: 0}" accept=".xlsx,.xls" :headers="{'access-token': Cookies.get(constant.tokenName)}" :on-success="fileSuccess" :before-upload="beforeUpload" :show-upload-list="false">
            <Button type="primary" v-if="isSuper" :loading="fileLoading" style="margin-right:20px;">导入老师数据</Button>
          </Upload>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import util from '@/utils/tools.js';
// import data from '../../dataset/data.vue';
import CreateUser from '@/components/createUser';
import Cookies from "js-cookie";
import CONSTANT from "@/utils/constant.js";
import { MessageBox } from "element-ui";
export default {
  components: { CreateUser },
  name: "userList",
  data() {
    const validateMobile = (rule, value, callback) => {
      if (value.length <= 0) {
        return callback();
      }

      if (value.length > 11) {
        return callback(new Error(this.$t('手机号码最长11位')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('手机号码最少11位')));
      }
      callback();
    };

    return {
      userModal: false,
      Cookies: Cookies,
      tableColumn: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: '80',
        },
        {
          title: '所属组织',
          render: (h, params) => {
            return h('span', params.row.site ? params.row.site.name : '');
          }
        },
        {
          title: '昵称',
          key: 'nickname',
          render: (h, para) => {
            return h('router-link', {
              props: {
                to: {
                  name: this.isSuper ? 'user/detail' : 'manager/user/detail',
                  params: {
                    id: para.row.id,
                    tab: 'index'
                  }
                }
              },
              style: {
                color: '#2db7f5',
              }
            }, para.row.nickname)
          }
        },
        {
          title: '姓名',
          key: 'realname',
        },
        {
          title: '手机号码',
          key: 'mobile',
          width: '130',
        },
        {
          title: '角色',
          key: 'roles.item_name',
          render: (h, params) => {
            let roles = params.row.roles.map((item) => {
              return item.role.description;
            }).join(',')
            return h('span', params.row.roles.length > 0 ? roles : '');
          }
        },
        {
          title: '状态',
          key: 'status',
          width: '100',
          render: (h, params) => {
            return h('span', this.statusObj[params.row.status]);
          }
        },
        {
          title: '星级评分',
          key: 'star',
          width: 100,
          render: (h, params) => {
            return h('span', params.row.star);
          }
        },
        {
          title: '注册和更新时间',
          key: 'created_at',
          render: (h, params) => {
            return h('span',
              util.timeFormatter(new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm') + "\n" +
              util.timeFormatter(new Date(+params.row.updated_at * 1000), 'yyyy-MM-dd hh:mm')
            );
          }
        },
        {
          title: '操作',
          key: 'op',
          minWidth: 90,
          render: (h, params) => {
            return h('div', [
              this.isSuper && params.row.access_token_link ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$copyText(params.row.access_token_link).then((e) => {
                      this.$Message.success(this.$t('codekey_my_copy_success'));
                    }, (e) => {
                      this.$Message.success(this.$t('codekey_my_copy_fail'));
                    })
                  }
                }
              }, '快捷登录') : '',
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.remove(params.row)
                  }
                }
              }, '删除'),
            ]);
          }
        },
      ],
      superColumns: [
        {
          title: 'ID',
          key: 'id',
          minWidth: 90,
          fixed:"left"
        },
        {
          title: '所属租户',
          tooltip: true,
          minWidth:150,
          fixed:"left",
          render:(h,params)=>{
            return util.tableColumnTooltip(h,params.row?.site?.name);
          }
        },
        {
          title: '专业/岗位',
          minWidth:100,
          render: (h, params) => {
            return util.tableColumnTooltip(h,params.row.siteUser && params.row.siteUser.department);
          }
        },
        {
          title: '昵称',
          key: 'nickname',
          minWidth:100,
          tooltip: true,
          render: (h, para) => {
            // return h('router-link', {
            //   props: {
            //     to: {
            //       name: this.isSuper ? 'user/detail' :'manager/user/detail',
            //       params: {
            //         id: para.row.id,
            //         tab: 'index'
            //       }
            //     }
            //   },
            //   style:{
            //     color:'#2db7f5',
            //     display:"block",
            //     width:"100%",
            //     overflow:"hidden",
            //     whiteSpace:"nowrap",
            //     textOverflow:"ellipsis"
            //   }
            // }, `${para.row.nickname} (${para.row.realname})`)

            // let texts = "";
            // if (para.row.nickname) {
            //   texts += para.row.nickname;
            // }
            // if (texts.length > 10) {
            //   texts = texts.substring(0, 10) + "...";
            // }
            return h('Tooltip', {
              props: {
                placement: "bottom-start",
                transfer: true,
              },
              style: {
                color: '#2db7f5',
                display: "block",
                width: "100%",
              }
            }, [
              h('router-link', {
                props: {
                  to: {
                    name: this.isSuper ? 'user/detail' : 'manager/user/detail',
                    params: {
                      id: para.row.id,
                      tab: 'index'
                    }
                  },
                },
                style:{
                  color:'#2db7f5',
                  display:"block",
                  width:"100%",
                  textOverflow:"ellipsis",
                  overflow:"hidden",
                  whiteSpace:"nowrap"
                }
              }, para.row.nickname),
              h("span", {
                slot: "content",
                style: {
                  wordBreak: "break-all",
                  whiteSpace: "normal"
                }
              }, `${para.row.nickname}`),
            ]
            )
          }
        },
        {
          title: '真实姓名',
          key: 'realname',
          minWidth:100,
        },
        {
          title:"实名认证",
          key:"is_verify_realname",
          minWidth:100,
          render:(h,params)=>{
            return h('div',params.row.is_verify_realname==1?'是':'否')
          }
        },
        {
          title: '手机号码',
          key: 'mobile',
          minWidth: 150,
          render: (h, params) => {
            return h('div', {
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              },
            }, [
              h('span', {}, params.row.mobile),

              this.isSuper && params.row.mobile && !params.row.myStatus ? h('img', {
                attrs: {
                  src: require('../../../assets/images/new_icon/Unlock.png'),
                  width: 12,
                  // height:20
                },
                style: {
                  marginLeft: '10px',
                },
                on: {
                  click: () => {
                    let data = {
                      id: params.row.id,
                      site_id: -1
                    }
                    this.api.user.getUserMobile(data).then((res) => {
                      params.row.mobile = res.mobile;
                      this.$set(params.row, 'myStatus', true);
                    })
                  }
                }
              }, '') : '',


            ]);
          }
        },
        {
          title: '角色',
          key: 'roles.item_name',
          minWidth:100,
          render: (h, params) => {
            let roles = params.row.roles.map((item) => {
              return item.role.description;
            }).join(',')
            return h('span', params.row.roles.length > 0 ? roles : '');
          }
        },
        {
          title: '状态',
          key: 'status',
          width: '100',
          render: (h, params) => {
            return h('span', this.statusObj[params.row.status]);
          }
        },
        {
          title: '星级评分',
          key: 'star',
          minWidth: 130,
          render: (h, params) => {
            return h('span', params.row.star + ' (积分:'+params.row.score+')');
          }
        },
        // {
        //   title: "积分",
        //   //key: "",
        //   render: (h, params) => {
        //     return h('span', params.row.score);
        //   }
        // },
        {
          title: '注册和更新时间',
          key: 'created_at',
          minWidth: 130,
          render: (h, params) => {
            return h('div', [
              h("div",util.timeFormatter(new Date(+params.row.created_at * 1000), 'yy-MM-dd hh:mm')),
              h("div",util.timeFormatter(new Date(+params.row.updated_at * 1000), 'yy-MM-dd hh:mm'))
            ]);
          }
        },
        {
          title: '操作',
          key: 'op',
          width: 260,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              // this.isSuper && params.row.access_token_link ? h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.$copyText(params.row.access_token_link).then(() => {
              //         this.$Message.success(this.$t('codekey_my_copy_success'));
              //       }, () => {
              //         this.$Message.success(this.$t('codekey_my_copy_fail'));
              //       })
              //     }
              //   }
              // }, '快捷登录') : '',
              this.isSuper && !this.isManager ? h("el-button", {
                props: {
                  type: "primary",
                  size: "mini"
                },
                style: {
                  marginRight: "5px"
                },
                on: {
                  click: () => {
                    this.showRemark(params.row);
                  }
                }
              }, "跟进") : "",
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, '编辑'),
              // h('Button', {
              //   props: {
              //     type: 'error',
              //     size: 'small'
              //   },
              //   on: {
              //     click: () => {
              //       this.remove(params.row)
              //     }
              //   }
              // }, '删除'),
              h('el-select', {
                  props:{
                    transfer: true,
                    placeholder:this.$t('codekey_manage_more'),
                    size: 'mini',
                    clearable:true,
                    value:'',
                  },
                  style:{
                    width:'80px',
                    marginLeft:'5px'
                  },
                  on: {
                    'change':(event) => {
                      if(event === '快捷登录'){
                        this.$copyText(params.row.access_token_link).then(() => {
                          this.$Message.success(this.$t('codekey_my_copy_success'));
                        }, () => {
                          this.$Message.success(this.$t('codekey_my_copy_fail'));
                        });
                      }else if(event==="删除"){
                        this.remove(params.row)
                      }
                    }
                  },
                },
                [
                this.isSuper && params.row.access_token_link?h('el-option',{
                    props: {
                      value: '快捷登录'
                    }
                  },"快捷登录"):'',
                  h('el-option',{
                    props:{
                      value:"删除"
                    },
                    style:{
                      color:"red"
                    }
                  },"删除")
                ])
            ]);
          }
        },
      ],
      data: [],
      total: 0,
      page: 1,
      pageSize: 10,
      statusObj: {},
      loading: false,
      formItem: {
        name: '',
        nickName: '',
        mobile: '',
        role: '',
        status: '0',
        username: '',
        password: '',
        site_id: '',
      },
      formRule: {
        site_id: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        name: [
          { required: false, message: '请输入名称', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        username: [
          { required: false, trigger: 'blur' },
        ],
        mobile: [
          { required: false, validator: validateMobile, trigger: 'blur' },
        ],
        password: [
          { required: false, trigger: 'blur' },
        ],
        role: [
          { required: true, message: '请选择角色' },
        ],
        status: [
          { required: true, message: '请选择状态' },
        ]
      },
      statusList: [],
      roleList: [],
      curData: {},
      editLoading: false,
      deleModal: false,
      deleLoading: false,
      siteId: '',
      keyword: '',
      roleObj: {},
      siteList: {},
      searchRole: '',
      changeSiteModal: false,
      changeSiteFormItem: {
        siteId: '',
        userIds: '',
        groupId: '',
      },
      changeSiteFormRule: {
        siteId: [
          { required: true, message: '请选择组织', trigger: 'blur' }
        ],
        userIds: [
          { required: true, message: '请输入用户id', trigger: 'blur' }
        ],
        groupId: [
          { message: '请选择班级', trigger: 'blur' }
        ],
      },
      groupList: [],
      cityId: [],
      cityList: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true
      },
      date: [],
      starType: '',
      starTypeList: [],
      searchSiteList: [],
      searchSiteId: '',

      tabType: -1,
      typesObj: {},
      roleColor: ['#164e63', '#0e7490', '#06b6d4', '#0369a1', '#0ea5e9', '#1d4ed8', '#60a5fa', '#164e63', '#0e7490', '#06b6d4', '#0369a1', '#0ea5e9', '#1d4ed8', '#60a5fa'],
      util: util,
      remarkModal: false,
      content: '',
      remarkList: [],
      remarkLoading: false,
      fileLoading: false,
      ruleForm: {
        role:'role_teacher',
        date:[],
      },
      rules: {
        role: [
          {required: true, message: '请选择角色', trigger: 'change'}
        ],
        date: [
          {type: 'array', required: true, message: '请选择日期', trigger: 'change'}
        ],
      },
      exportModal:false,
      realNameList:[
        {
          name:"全部",
          id:"-1"
        },
        {
          name:"否",
          id:"0"
        },
        {
          name:"是",
          id:"1"
        },
      ],
      is_verify_realname:"",
      //
      updateRemarkShow:false,
      updateForm:{
        content:"",
        id:""
      },

      importDrawerShow:false,
      batchChangeSite:[],
    }
  },
  props: {
    isUser: {
      default: false,
      type: Boolean
    },
    isSuper: {
      default: false,
      type: Boolean
    },
    isManager: {
      default: false,
      type: Boolean
    }
  },
  created() {
    this.siteId = this.$route.params.id;
    if (this.isSuper) {
      this.tableColumn = this.superColumns;
    } else {
      this.tableColumn = this.columns;
    }
    this.getFromData();
    this.getUserList();
    this.getPlaceList();
  },
  methods: {
    changeTab(id) {
      this.tabType = id;

      this.page = 1;
      this.getUserList();
    },
    searchList() {
      this.page = 1;
      this.getUserList();
    },
    getFromData() {  //获取用户的角色列表
      let params = { site_id: -1 };
      this.api.user.userForm(params).then((res) => {
        console.log("角色列表", res.roles);
        // this.siteList = res.sites;
        this.roleList = [];
        for (let name in res.roles) {
          this.roleList.push({
            label: res.roles[name],
            value: name
          })
        }

        // this.statusList = [];
        //
        // for(let name in res.statuses){
        //   this.statusList.push({
        //     label:res.statuses[name],
        //     value:name
        //   })
        // }
      })
    },
    changeRole(val) {
      console.log(val);
      this.page = 1;
      this.searchRole = val;
      this.getUserList();
    },
    changeDate(data) {
      this.date = data;
      this.page = 1;
      this.getUserList();
    },
    getUserList() {
      let params = {
        site_id: (this.isSuper ? -1 : 0),
        user_id: -1,
        page: this.page,
        pageSize: this.pageSize,
        role: this.searchRole,
        star_type: this.starType,
        province_id: this.cityId[0],
        city_id: this.cityId[1] || '',
        start_time: new Date(this.date[0]).getTime() / 1000,
        end_time: new Date(this.date[1]).getTime() / 1000,
        site_type: this.tabType,
        is_with_last_follow: 1,
        with_score: 1,
        is_verify_realname:this.is_verify_realname
      };
      if (this.siteId) {
        params.site_id = this.siteId;
      }
      if (this.searchSiteId) {
        params.site_id = this.searchSiteId;
      }
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      // if (this.isManager) { //组织管理-老师管理
      //   params.role = 'role_teacher';
      // }
      this.loading = true;
      this.api.user.userList(params).then((res) => {
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        this.roleObj = res.roles;
        this.typesObj = res.site_types;
        this.statusList = [];

        for (let name in res.statuses) {
          this.statusList.push({
            label: res.statuses[name],
            value: name
          })
        }
        this.starTypeList = [];
        for (let name in res.star_types) {
          this.starTypeList.push({
            label: res.star_types[name],
            value: name
          })
        }
      }).catch((err) => {
        this.loading = false;
      })
    },
    changePage(page) {
      this.page = page;
      this.getUserList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.page = 1;
      this.getUserList();
    },
    add() {  //创建用户
      this.curData = {};
      this.userModal = true;
    },
    edit(data) { //编辑
      this.curData = data;
      this.userModal = true;
    },
    // confirm(){
    //   this.$refs['formValidate'].validate((valid) => {
    //     console.log(valid)
    //     if (valid) {
    //       let params = {
    //         // site_id:this.formItem.site_id,
    //         realname:this.formItem.name,
    //         nickname:this.formItem.nickName,
    //         username:this.formItem.username,
    //         mobile:this.formItem.mobile,
    //         password:this.formItem.password,
    //         roles:this.formItem.role.join(','),
    //         status:this.formItem.status
    //       };
    //       if(!this.isManager){
    //         params.site_id = this.formItem.site_id;
    //       }
    //       let apiUrl = ''
    //       if(this.curData.id){
    //         params.user_id = this.curData.id;
    //         apiUrl = 'userUpdate';
    //       }else{
    //         if(this.siteId){
    //           params.site_id = this.siteId;
    //         }
    //         apiUrl = 'userCreate';
    //       }
    //       this.editLoading = true;
    //
    //       this.api.user[apiUrl](params).then((res)=>{
    //         this.editLoading = false;
    //         this.userModal = false;
    //         this.getUserList();
    //       }).catch((err)=>{
    //         this.editLoading = false;
    //       })
    //
    //
    //     }
    //   })
    // },
    // cancel(){
    //   this.userModal = false;
    //   this.$refs.formValidate.resetFields();
    // },
    closeModal() {
      this.userModal = false;
      this.getUserList();
    },
    remove(data) { //删除
      this.curData = data;
      this.deleModal = true;
    },
    deleCancel() {
      this.deleModal = false;
    },
    dele() {
      let params = {
        user_id: this.curData.id
      }
      this.deleLoading = true;
      this.api.user.userDelete(params).then((res) => {
        this.deleLoading = false;
        this.deleModal = false;
        this.getUserList();
      }).catch((err) => {
        this.deleLoading = false;
      })
    },
    goMysite() {
      this.$router.push({
        name: 'mysite',
        params: {
          tab: 'invitation'
        }
      })
    },
    changeSite() {
      this.changeSiteModal = true;
      this.changeSiteFormItem = {
        siteId: '',
        userIds: ''
      }
    },
    cancelChangeSiteModal() {
      this.changeSiteModal = false;
    },
    confirmChangeSite() {
      this.$refs['formChangeSiteValidate'].validate((valid) => {
        if (valid) {
          let params = {
            site_id: this.changeSiteFormItem.siteId,
            user_id_str: this.changeSiteFormItem.userIds,
            group_id: this.changeSiteFormItem.groupId
          }
          this.api.user.siteChangeUserSite(params).then((res) => {
            this.$Message.success('操作成功');
            this.changeSiteModal = false;
            // this.$Notice.open({
            //   title: '导入详情',
            //   desc: 'This notification does not automatically close, and you need to click the close button to close.',
            //   duration: 0,
            //   render: h => {
            //     return h('div', [
            //       h('p',{
            //         style:{
            //           fontSize:'14px',
            //           margin:'0 0 10px 0',
            //           wordBreak: 'break-all',
            //           lineHeight:'20px'
            //         }
            //       }, '导入成功：' + res.addUserIds.join(',')),
            //       h('p',{
            //         style:{
            //           fontSize:'14px',
            //           margin:'0 0 10px 0',
            //           wordBreak: 'break-all',
            //           lineHeight:'20px'
            //         }
            //       }, '已存在：' + res.existUserIds.join(',')),
            //     ])
            //   }
            // });
            this.getUserList();
          })
        }
      })

    },
    getGroupList() {
      let params = {
        pageSize: 99,
        user_id: '-1',
        site_id: this.changeSiteFormItem.siteId,
      };

      this.api.user.groupList(params).then((res) => {
        this.groupList = res.list;
      })
    },
    getPlaceList() { //获取地域
      this.api.site.placeList().then((res) => {
        this.cityList = res.list;
      })
    },
    rankRemoteSite(query,type) {
      console.log(query, 'query')
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getSiteList(query,type);
      }, 300)

    },
    getSiteList(data,type) {
      let params = {
        pageSize: 20,
        page: 1,
        keyword: data,
      };
      this.api.site.siteList(params).then((res) => {
        if(type == 'batch'){
          this.batchChangeSite = res.list;
        }else{
          this.searchSiteList = res.list;
        }
      })
    },
    showPhone(data) {
      let params = {
        id: data.id,
        site_id: -1
      }
      this.api.user.getUserMobile(params).then((res) => {
        data.mobile = res.mobile;
        this.$set(data, 'myStatus', true);
      })
    },
    quickLogin(data) {
      this.$copyText(data.access_token_link).then((e) => {
        this.$Message.success(this.$t('codekey_my_copy_success'));
      }, (e) => {
        this.$Message.success(this.$t('codekey_my_copy_fail'));
      })
    },
    showRemark(data) {
      this.curData = data;
      this.content = '';
      this.remarkList = [];
      this.remarkModal = true;
      this.getRemarkList();
    },
    getRemarkList() {  //获取评论列表
      let params = {
        followup_site_id: this.curData?.site?.id || "0",
        followup_user_id: this.curData.id,
      };
      this.remarkLoading = true;
      this.api.customer.customerFollowList(params).then((res) => {
        this.remarkLoading = false;
        this.remarkList = res.list;
        this.remarkList.forEach((item) => {
          item.created_at = util.timeFormatter(new Date(+item.created_at * 1000), 'yyyy-MM-dd hh:mm');
        })
      }).catch((err) => {
        this.remarkLoading = false;
      })
    },
    confirmRemark() {
      let params = {
        followup_site_id: this.curData?.site?.id || "0",
        followup_user_id: this.curData.id,
        content: this.content
      };
      this.content = '';
      this.api.customer.customerFollowCreate(params).then((res) => {
        this.$Message.success('操作成功');
        this.getRemarkList();
      })
    },
    cancelRemark() {
      this.remarkLoading = false;
      this.getUserList();
    },
    goInfo(data) {
      this.$router.push({
        name: this.isSuper ? 'user/detail' : 'manager/user/detail',
        params: {
          id: data.id,
          tab: 'index'
        }
      })
    },
    fileSuccess(response, file, fileList) {  //上传文件成功

      let params = {
        uploadfile_id: response.data.info.id
      }
      this.api.user.teacherDataImport(params).then((res) => {
        this.fileLoading = false;
        this.$Message.success('上传文件成功');
      }).catch((err) => {
        this.fileLoading = false;
      })

    },
    beforeUpload() {
      this.fileLoading = true;
    },
    exportRecord(){
      this.exportModal = true;
      this.ruleForm.role = 'role_teacher';
      this.ruleForm.date = [];
      this.$nextTick(()=>{
        this.$refs['ruleForm'].resetFields();
      })

    },
    cancelExport(){
      this.exportModal = false;
    },
    confirmExportRecord(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            user_role:this.ruleForm.role,
            start_time:this.ruleForm.date[0]/1000,
            end_time:this.ruleForm.date[1]/1000,
            site_id:-1,
            user_id:-1
          }
          this.api.score.exportRecord(params).then((res)=>{
            let uploadParams = {
              file:res.filepath
            };
            this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
              util.downLoad(res);
              this.exportModal = false;
            })
          })
        } else {
          return false;
        }
      });
    },
    updateRemark(row){
      this.updateForm.id=row.id;
      this.updateForm.content=row.content;
      this.updateRemarkShow=true;
    },
    updateRemarkOK(){
      this.api.user.updateFollow(this.updateForm).then(()=>{
        this.$Message.success("编辑成功");
        this.updateRemarkShow=false;
        this.getRemarkList();
      });
    },
    updateRemarkDrawerClose(){
      this.updateForm={
        id:"",
        content:""
      }
    },
    deleteRemark(item){
      MessageBox.confirm('该操作不可逆是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.user.deleteFollow({
          id: item.id
        }).then(() => {
          this.getRemarkList();
          this.$Message.success("删除成功");
        });
      });
    },
    downloadTemplate(){
      util.downloadFile(
        this,
        CONSTANT.STATIC_URL + "/example_files/import_teacher_template.xlsx"
      );
    }
  }
}
</script>

<style scoped lang="scss">
.margin-bottom-10 {
  margin-bottom: 20px;
}

.user-list-top {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.page {
  margin-top: 20px;
  text-align: right;
}

.site-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  height: 40px;

  .site-tab-l {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    font-size: 16px;
    color: #666666;

    >p {
      padding-top: 6px;
      margin-right: 28px;
      height: 100%;
      cursor: pointer;
    }

    .active {
      font-weight: bold;
      color: #4A79FF;
      border-bottom: 4px solid #5782FF;
      ;
    }
  }
}

.search {
  //margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 14px;
  color: #333333;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .search_input {
    font-size: 14px;

    >span {
      padding: 0 5px;
      color: #2d8cf0;
    }
  }

  .search-item {
    margin-left: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;

    .name {
      padding: 5px 0;
      text-align: right;
      font-weight: bold;
    }

    .cont {
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values {
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div {
          padding: 0 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }

        >div.active {
          font-weight: bold;
          color: #4A79FF;
        }
      }
    }
  }
}

.table {
  width: 100%;

  .user-item {
    margin-bottom: 12px;
    border: 1px solid #E8EAEC;
    font-size: 14px;

    .user-item-top {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E8EAEC;
      height: 40px;
      background: #F8F8F9;

      >div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >span {
          padding: 0 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          >img {
            margin-right: 6px;
          }
        }
      }
    }

    .user-item-info {
      display: flex;
      justify-content: flex-start;

      .user-item-info-l {
        width: 18%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        border-right: 1px solid #E8EAEC;

        >img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        >div {
          flex: 1;
          width: 0;

          .user-item-info-l-name {
            font-weight: bold;
            color: #4E83FF;
            cursor: pointer;
          }

          >div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            >img {
              margin-left: 6px;
              width: 10px;
              height: 12px;
            }
          }

        }
      }

      .user-item-info-role {
        padding: 20px;
        width: 16%;
        border-right: 1px solid #E8EAEC;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;

        >span {
          margin: 0 5px 5px 0;
          padding: 5px 10px;
          background-color: #2d8cf0;
          color: #FFFFFF;
          border-radius: 4px;
        }
      }

      .user-item-info-score {
        width: 10%;
        padding: 20px;
        border-right: 1px solid #E8EAEC;
        text-align: center;
      }

      .user-item-info-time {
        width: 12%;
        padding: 20px;
        border-right: 1px solid #E8EAEC;
        text-align: center;
      }

      .user-item-info-subtit {
        margin-bottom: 8px;
      }

      .user-item-info-desc {
        flex: 1;
        width: 0;
        padding: 20px;
        border-right: 1px solid #E8EAEC;
      }

      .user-item-info-btn {
        padding: 20px;
        width: 24%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        >button {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.top-btn {
  display: flex;
  justify-content: flex-end;
}

.history {
  font-size: 14px;

  .history-tit {
    margin: 20px 0;
    font-weight: bold;
  }

  .history-list {
    max-height: 500px;
    overflow-y: auto;
    .content-wrap{
      display: flex;
      padding-right: 75px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .time {
    font-size: 14px;
    margin-bottom: 10px;

    >span {
      padding: 0 20px;
    }
  }

  .content {
    font-size: 14px;
    line-height: 26px;
    white-space: pre-wrap;
  }
}

.flexW {
  flex: 1;
}
</style>
<style>
.el-cascader-menu {
  height: 200px !important;
}
</style>
