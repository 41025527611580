<template>
  <!-- <Modal
      v-model="userModal"
      :title="curData.id ? '编辑用户' : '创建用户'"
      @on-cancel="cancel"
  > -->
  <el-drawer
      :visible.sync="drawerShow"
      :modal="true"
      direction="rtl"
      :size="800"
      @open="open"
      @close="cancel"
    >
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">{{ userId ? '编辑用户' : '创建用户' }}</div>
      <div class="drawer-title-btns">
        <Button @click="cancel" style="margin-right:10px;">取消</Button>
        <Button type="primary" @click="confirm" :loading="editLoading">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <el-form ref="formValidate" :model="formItem" :rules="formRule" label-width="80px">
        <el-form-item label="所属租户" prop="site_id" v-if="!isManager">
<!--          <Select filterable v-model="formItem.site_id" clearable>-->
<!--            <Option :value="item.id" v-for="item in siteList" :key="item.id + item.name">ID:{{ item.id }}  {{ item.name }}</Option>-->
<!--          </Select>-->
          <el-select v-model="formItem.site_id" ref="select" remote clearable :remote-method="rankRemoteSite" filterable
                  placeholder="请选择组织" >
            <el-option v-for="item in searchSiteList" :value="item.id" :key="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formItem.name" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="formItem.nickName" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="formItem.mobile" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input v-model="formItem.username" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="formItem.password" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select multiple v-model="formItem.role">
            <el-option :value="item.value" v-for="item in roleList" :key="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="formItem.status">
            <el-option :value="item.value" v-for="item in statusList" :key="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实名认证" prop="is_verify_realname">
          <RadioGroup v-model="formItem.is_verify_realname">
            <Radio :label="item.value" v-for="item in idCardState" :key="item.value">
              <span>{{ item.label }}</span>
            </Radio>
          </RadioGroup>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card_num">
          <el-input v-model="formItem.id_card_num" placeholder="请输入身份证号"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
  <!-- </Modal> -->
</template>

<script>
export default {
  name: "createUser",
  data(){
    const validateMobile = (rule, value, callback) => {
      if (value.length <= 0) {
        return callback();
      }

      if (value.length > 11) {
        return callback(new Error(this.$t('手机号码最长11位')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('手机号码最少11位')));
      }
      callback();
    };
    const validateIdNum= (rule, value, callback) => {
      const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if(this.formItem.is_verify_realname==="1" && !value){
        return callback("请输入身份证号");
      }
      if(this.formItem.is_verify_realname==="1" && !reg.test(value)){
        return callback("请输入正确的身份证号");
      }
      callback();
    };
    return{
      drawerShow:false,
      formItem:{
        name:'',
        nickName:'',
        mobile:'',
        role:'',
        status:'1',
        username:'',
        password:'',
        site_id:'',
        is_verify_realname:'0',
        id_card_num:""
      },
      formRule: {
        site_id: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        name: [
          { required: false, message: '请输入名称', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        username: [
          { required: false, trigger: 'blur'  },
        ],
        mobile: [
          { required: false, validator: validateMobile, trigger: 'blur'  },
        ],
        password: [
          { required: false, trigger: 'blur'  },
        ],
        role: [
          { required: true, message: '请选择角色' },
        ],
        status: [
          { required: true, message: '请选择状态' },
        ],
        id_card_num:[
          { validator:validateIdNum, trigger: 'blur'}
        ]
      },
      statusList:[],
      roleList:[],
      // siteList:[],
      idCardState:[
        {
          value: '0',
          label: '否'
        },
        {
          value: '1',
          label: '是'
        },
      ],
      editLoading:false,
      searchSiteList:[]
    }
  },
  props:{
    curData:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    isManager:{
      type:Boolean,
      default:false,
    },
    userId:{
      type:String,
      default:'',
    },
    userModal:{
      type:Boolean,
      default:false,
    },
    // statusList:{
    //   type:Array,
    //   default:()=>{
    //     return []
    //   }
    // },
    // roleList:{
    //   type:Array,
    //   default:()=>{
    //     return []
    //   }
    // },
  },
  // watch:{
  //   userId:{
  //     handler(newV){
  //       if(newV){
  //         this.getDetail(newV);
  //       }
  //     },
  //     immediate:true
  //   }
  // },
  created(){
    this.getFromData();
  },
  methods:{
    // 抽屉打开事件
    open(){
      // this.getSiteList();
      if(this.userId){
        this.getDetail(this.userId);
      }
    },
    getDetail(id){
      let params = {
        user_id:id
      }
      this.api.user.userDetail(params).then((res)=>{
        this.formItem.site_id = 0;
        if (res.user.site){
          this.formItem.site_id = res.user.site.id;
          this.getSiteList(res.user.site.name);
        }

        this.formItem.name = res.user.realname;
        this.formItem.nickName = res.user.nickname;
        this.formItem.username = res.user.username;
        this.formItem.mobile = res.user.mobile;
        this.formItem.password = res.user.password;
        this.formItem.id_card_num=res.user.id_card_num;
        this.formItem.is_verify_realname=res.user.is_verify_realname;
        this.formItem.role = [];
        if(res.user.auth_roles){
          for(let name in res.user.auth_roles){
            this.formItem.role.push(name)
          }
        }
        this.formItem.status = res.user.status;
      })
    },
    getFromData(){  //获取用户的角色列表
      let params = {site_id:-1};
      this.api.user.userForm(params).then((res)=>{
        this.roleList = [];
        for(let name in res.roles){
          this.roleList.push({
            label:res.roles[name],
            value:name
          })
        }
        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            label:res.statuses[name],
            value:name
          })
        }
      })
    },
    getSiteList(data) {
      let params = {
        pageSize: 20,
        page: 1,
        keyword: data,
      };
      this.api.site.siteList(params).then((res) => {
        this.searchSiteList = res.list;
      })
    },
    rankRemoteSite(query) {
      console.log(query, 'query')
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getSiteList(query);
      }, 300)

    },
    // 确定按钮事件
    confirm(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            // site_id:this.formItem.site_id,
            realname:this.formItem.name,
            nickname:this.formItem.nickName,
            username:this.formItem.username,
            mobile:this.formItem.mobile,
            password:this.formItem.password,
            roles:this.formItem.role.join(','),
            status:this.formItem.status,
            is_verify_realname:this.formItem.is_verify_realname,
            id_card_num:this.formItem.id_card_num
          };
          if(!this.isManager){
            params.site_id = this.formItem.site_id;
          }
          let apiUrl = ''
          if(this.userId){
            params.user_id = this.userId;
            apiUrl = 'userUpdate';
          }else{
            if(this.siteId){
              params.site_id = this.siteId;
            }
            apiUrl = 'userCreate';
          }
          this.editLoading = true;

          this.api.user[apiUrl](params).then(()=>{
            this.editLoading = false;
            // this.userModal = false;
            this.$emit('close');
          }).catch(()=>{
            this.editLoading = false;
          })
        }
      })
    },
    // 取消按钮事件
    cancel(){
      this.$emit('close');
      this.formItem={
        name:'',
        nickName:'',
        mobile:'',
        role:'',
        status:'1',
        username:'',
        password:'',
        site_id:'',
        is_verify_realname:'0',
        id_card_num:""
      },
      this.$refs.formValidate.resetFields();
    }
  },
  watch:{
    userModal(newVal){
      this.drawerShow=newVal;
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .ivu-select .ivu-select-dropdown{
    width:100% !important;
    min-width:auto !important;
  }
</style>
